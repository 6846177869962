import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from 'gatsby';
import Button from "common/src/components/Button";
import { Icon } from "react-icons-kit";
import { imac } from 'react-icons-kit/ikons/imac';
import { ic_flag } from 'react-icons-kit/md/ic_flag';
import { ic_warning } from 'react-icons-kit/md/ic_warning';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 style={{
      textAlign: "center"
    }}>Welcome to RepairSetup</h1>
    <h3 {...{
      "id": "repairsetup-is-so-called-fsmfield-service-management-software-system-that-aims-to-help-organize-your-field-operations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#repairsetup-is-so-called-fsmfield-service-management-software-system-that-aims-to-help-organize-your-field-operations",
        "aria-label": "repairsetup is so called fsmfield service management software system that aims to help organize your field operations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h3">{`RepairSetup`}</strong>{` is so called FSM(Field Service Management) software system, that aims to help organize your field operations.`}</h3>
    <h3 {...{
      "id": "you-might-be-a-small-company-or-one-man-show-operations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#you-might-be-a-small-company-or-one-man-show-operations",
        "aria-label": "you might be a small company or one man show operations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`You might be a small company or one man show operations:`}</h3>
    <ul style={{
      fontSize: "1.2em",
      marginLeft: 20
    }}>
  <li><b>Home Appliance Technician</b> — repairing home appliances.</li>
  <li><b>Plumber</b> — repairing home pipe systems.</li>
  <li><b>Handyman</b> — doing any other general repairment.</li>
  <li><b>Doctor</b> — doing home visiting in current COVID-19 situation.</li>
    </ul>
    <h3 {...{
      "id": "repairsetup-will-help-you-keep-track-of-your-current-work-and-documentation-for-a-very-reasonable-price",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#repairsetup-will-help-you-keep-track-of-your-current-work-and-documentation-for-a-very-reasonable-price",
        "aria-label": "repairsetup will help you keep track of your current work and documentation for a very reasonable price permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h3">{`RepairSetup`}</strong>{` will help you keep track of your current work and documentation for a very reasonable price.`}</h3>
    <hr></hr>
    <h2 {...{
      "id": "features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features",
        "aria-label": "features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Insurers`}</strong>{` that you may get work from. Currently supported are `}<strong parentName="p">{`American Home Shield`}</strong>{` (`}<strong parentName="p">{`AHS`}</strong>{`), `}<strong parentName="p">{`Home Shield Alliance`}</strong>{` (`}<strong parentName="p">{`HSA`}</strong>{`), `}<strong parentName="p">{`Cinch`}</strong>{`, `}<strong parentName="p">{`Old Republic Home Protection`}</strong>{` (`}<strong parentName="p">{`ORHP`}</strong>{`), and more added upon request.
Client's `}<strong parentName="p">{`Name, Address, Telephone numbers`}</strong>{`, and work details are auto-imported in the system from `}<strong parentName="p">{`Insurer email`}</strong>{` and are ready for action.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/docs/usage/dispatch"
          }}><strong parentName="a">{`Dispatching`}</strong></a>{` of home visits with full `}<strong parentName="p">{`Map`}</strong>{` support.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/docs/usage/settings#users"
          }}><strong parentName="a">{`Multi-user`}</strong></a>{` support with `}<strong parentName="p">{`Admin`}</strong>{` and `}<strong parentName="p">{`Technician`}</strong>{` roles. Technician can see only what they need.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Full mobile support`}</strong>{`, so access to the system is easy on telephone out on the `}<strong parentName="p">{`field`}</strong>{`, tablet, or regular computer at the `}<strong parentName="p">{`office`}</strong>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Easy multi-tasking`}</strong>{` with support for multiple browser tabs with `}<strong parentName="p">{`different documents`}</strong>{` opened at the `}<strong parentName="p">{`same time`}</strong>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/docs/documents/invoice"
          }}><strong parentName="a">{`Easy invoicing`}</strong></a>{` with just `}<strong parentName="p">{`1-click`}</strong>{`. Usually with invoice creation, it will have all fields already filled for you, and ready for print. Easy printing and keeping track of each invoice status.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Zero maintenance`}</strong>{` from your side, so you and your `}<strong parentName="p">{`employees`}</strong>{` can use the system from `}<strong parentName="p">{`everywhere`}</strong>{` with Internet access 24/7. We also will make `}<strong parentName="p">{`backups`}</strong>{`, to make sure your data is safe.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Data return`}</strong>{` in case you happen to quit our paid services. We are `}<strong parentName="p">{`obligated`}</strong>{` to return `}<strong parentName="p">{`all your data`}</strong>{` collected in suitable format, and remove it from our servers. Also we will support you in migration to other system, with any technical advices.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Migration help`}</strong>{` in case you want to come to us from some other system. You will have our full support in gathering your data from other system, and importing it to ours.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`inventory`}</strong>{` system, to keep track of any `}<strong parentName="p">{`stock items`}</strong>{` you may work with. `}<em parentName="p">{`We are still working on it, and may be we can do it along your liking.`}</em></p>
      </li>
    </ul>
    <blockquote>
  <p>
    <Icon icon={ic_warning} size={32} mdxType="Icon" />
    We are open to any <b>suggestions from your side</b>, and will add any <b>functionality</b> you may need. Just <a href="/contact-us"><b>Contact Us</b></a> and we will do the rest.
  </p>
    </blockquote>
    <hr></hr>
    <h3>
  If interested, make sure to check our
  <Link to="/docs/getting-started" mdxType="Link">
  <Button style={{
          margin: "0px 10px"
        }} type="submit" variant="extendedFab" colors="primaryWithBg" title="Getting Started Page" iconPosition="left" icon={<Icon icon={ic_flag} size={32} mdxType="Icon" />} aria-label="Open getting started" mdxType="Button" />
  </Link>
or head to the
  <a href="https://demo.repairsetup.com">
  <Button style={{
          margin: "0px 10px"
        }} variant="extendedFab" colors="primaryWithBg" title="Demo" iconPosition="left" icon={<Icon icon={imac} size={32} mdxType="Icon" />} aria-label="Open demo" mdxType="Button" />
  </a>
    </h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      